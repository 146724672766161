export const base = 'white'
export const gray = '#f1f2f7'
export const gray10 = '#f1f1f4'
export const gray20 = '#e4e5e9'
export const gray40 = '#D8D8D8'
export const gray80 = '#6f7077'
export const indigo = '#3f51b5'
export const indigoDarken10 = '#364495'
export const indigoLighten80 = '#b7c1f8'
export const yellow = '#ffc107'
export const green = '#4caf50'
export const green80 = '#155725'
export const danger = '#ef5350'
export const orange = 'orange'
export const blue = '#001493'

export const primary = '#E14038'
export const secondary = '#764AF3'
export const text = '#152B2C'
export const lightBlue = '#5D8AAB'
export const tertiary = '#21415F'
